<template>
  <main class="px-2 md:px-4 py-6 flex flex-col gap-6">
    <h4
      class="capitalize md:uppercase md:text-sm md:text-left text-xs font-medium text-headerText"
    >
      Book Keeping / Client
    </h4>

    <!-- //// Search and Filter ///// -->

    <div class="flex flex-col md:flex-row items-center justify-between gap-4">
      <div class="flex-grow w-full">
        <easiSearch
          placeholder="Search for client "
          v-model="searchInput"
          @search="onSearch"
          :suggestion="false"
        />
      </div>

      <div class="flex items-center gap-4">
        <!-- <easiButton
          rounded="2xl"
          variant="outlined"
          color="gray"
          class="bg-white text-dark-600 gap-2"
        >
          <img class="" src="@/assets/icons/filter.svg" alt="" />
          Filter
        </easiButton> -->
        <div class="relative">
          <easiButton
            rounded="2xl"
            variant="outlined"
            color="gray"
            @click="toggledownload = true"
            class="bg-white text-dark-600 gap-2"
          >
            <i class="pi pi-download text-sm" />

            Download
          </easiButton>
          <div
            v-if="toggledownload"
            class="absolute h-auto w-52 right-0 top-16 shadow-lg bg-white flex flex-col text-sm py-2 rounded-xl"
            style="z-index: 2"
          >
            <div
              v-for="option in downloadOptions"
              :key="option.name"
              class="flex items-center gap-3 cursor-pointer px-4 py-2 hover:bg-newLimeGreen"
              @click="option.action()"
            >
              {{ option.name }}
            </div>
          </div>

          <div
            @click="toggledownload = false"
            :class="!toggledownload ? 'pointer-events-none' : ''"
            class="bg-transparent fixed inset-0"
          ></div>
        </div>

        <easiButton @click="$router.push({ name: 'AddClient' })" class="gap-2">
          <i class="pi pi-plus text-sm" /> Add New Client</easiButton
        >
      </div>
    </div>

    <!-- //// Table ///// -->
    <ListClients v-model:loading="isLoading" />
    <easiLoading v-if="loadingModal" />
  </main>
</template>

<script setup>
import ListClients from "@/components/BookKeeping/Client/ListClients";
import { computed, ref, onMounted, reactive, watch } from "vue";
import { useDataStore } from "@/stores/data.js";
import { getClients, DOWNLOAD_CLIENT } from "@/services/book-keeping/client";
import { helperFunctions } from "@/composable/helperFunctions";
import { debouncedValue } from "@/composable/debouncedValue";

const { downloadBase64 } = helperFunctions;

const store = useDataStore();
const storeBookKeepingClients = computed(
  () => store.getBookkeepingClients.docs || []
);
const props = defineProps({
  loading: Boolean,
});
const emit = defineEmits(["update:loading"]);

const { query, mutate } = store;
const loadingModal = ref(false);
onMounted(async () => {
  try {
    loadingModal.value = !storeBookKeepingClients.value.length;
    await getClients();
  } catch (error) {
    console.log(error);
  } finally {
    loadingModal.value = false;
  }
});

const isLoading = ref(false);

const searchInput = ref("");
const toggledownload = ref(false);

const debounceSearch = debouncedValue(searchInput);
watch(debounceSearch, () => {
  onSearch();
});
const onSearch = async () => {
  try {
    searchInput.value ? (isLoading.value = true) : (loadingModal.value = true);
    await getClients({ search: searchInput.value });
  } catch (error) {
    console.log(error);
  } finally {
    isLoading.value = false;
    loadingModal.value = false;
  }
};
const apiFilter = reactive({
  search: "null",
});
// watch(
//   apiFilter,
//   () => {
//     getClients();
//   },
//   {
//     deep: true,
//   }
// );

const download = async (type) => {
  console.log("type", type);

  try {
    isLoading.value = true;

    const payload = {
      limit: 999,
      page: 1,
    };
    const res = await DOWNLOAD_CLIENT(payload, type);
    // console.log("res is", res.data.attachments[0].Base64Content);
    if (res.success === true) {
      const base64 = res.data.attachments[0].Base64Content;
      const fileName = res.data.attachments[0].Filename;
      downloadBase64(base64, fileName);
    } else {
      toast.error("Download failed.");
    }
  } catch (e) {
    console.error(e);
  } finally {
    isLoading.value = false;
  }
};
const downloadOptions = [
  {
    name: "CSV",
    action: () => download("csv"),
  },
  {
    name: "pdf",
    action: () => download("pdf"),
  },
];
</script>

<style></style>
